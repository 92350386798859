<template>
  <div class="w-work-center-wrap">
    <div class="container">
      <div class="search-wrap">
        <div class="search">
          <el-autocomplete
            hide-loading
            class="w-input-sty input-solid"
            v-model="searchVal"
            popper-class="my-autocomplete"
            :fetch-suggestions="querySearch"
            placeholder="请输入流程名称"
            @select="selectProcess"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-search"
            ></i>
            <template slot-scope="{ item }">
              <span
                class="search-item-icon"
                :class="item.icon"
                :style="{background: item.iconColour}"
              > </span>
              <span>{{ item.name }}</span>
              <span class="desc">{{ item.desc }}</span>
            </template>
          </el-autocomplete>
        </div>
      </div>
      <div
        class="workOrder-container"
        id="workOrder-container"
      >
        <div
          class="workOrder-btns-container"
          v-if="navList.length > 0"
        >
          <div
            class="btns-wrap"
            id="btns-wrap"
          >
            <div
              class="btns-row"
              v-for="(item, index) in navList"
              :key="index"
              :id="'workOrder' + index"
            >
              <div class="title">
                {{item.groupName}}
              </div>
              <div
                class="btns"
                v-if="item.children.length > 0"
              >
                <div
                  class="btn"
                  v-for="(item2, key) in item.children"
                  :key="key"
                  @mouseover="mouseOver(index, key)"
                  @mouseleave="mouseLeave(index, key)"
                >
                  <div
                    class="option"
                    v-show="item2.showOption"
                  >
                    <div
                      class="item"
                      v-for="(icon, i) in btnList"
                      :key="i"
                      @click="setting(i, item2)"
                    >
                      <div>
                        <i :class="icon.icon"></i>
                      </div>
                      <div>{{icon.name}}</div>
                    </div>
                  </div>
                  <div
                    class="icon-img"
                    :style="{background: item2.iconColour}"
                  >
                    <i :class="[item2.icon]">
                    </i>
                  </div>
                  <div>
                    <div class="name ellipsis">{{item2.name}}</div>
                    <div class="desc ellipsis">{{item2.desc}}</div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="no-data"
              >
                <p>该分组暂无流程</p>
              </div>
            </div>
          </div>
          <div class="nav">
            <ul id="nav">
              <li
                v-for="(item, index) in navList"
                :key="index"
                :class="{'active': item.locate}"
              >
                <span @click="locate(index)">
                  <i
                    v-if="item.locate"
                    class="icon-work-point"
                  ></i>{{item.groupName}}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div
          v-else
          class="no-data"
        >
          <div>
            <img src="@/assets/images/public/not-plandata.png">
            <p>暂无相关数据</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 工单设置预览页面 -->
    <div
      class="preview-page-wrap"
      v-if="showPreviewDialog"
    >
      <div v-if="configId">
        <previewComp
          :configId="configId"
          :processName="processName"
          @closePreviewEmit="closePreviewEmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import previewComp from '../components/previewComp.vue'
import api from '@/api'
export default {
  data () {
    return {
      searchVal: '',
      searchList: [],
      filtroProject: '',
      navList: [], // 流程列表数据
      index1: 0, // 流程列表的下标
      index2: 0, // 流程列表的children的下标
      anchor: 0, // 锚点
      btnList: [{
        name: '预览',
        icon: 'icon-work-scan'
      }, {
        name: '提交',
        icon: 'icon-work-submit'
      }, {
        name: '添加常用',
        icon: 'icon-work-add'
      }],
      showPreviewDialog: false,
      configId: 0,
      processName: ''
    }
  },
  props: {
    projectId: {
      type: Number,
      default: 0
    },
    projectName: {
      type: String,
      default: ''
    }
  },
  watch: {
    projectId (newVal, oldVal) {
      this.filtroProject = this.projectId
      this.anchor = 0
      this.getList('init')
    }
  },
  components: {
    previewComp
  },
  mounted () {
    this.filtroProject = this.projectId
    this.getList('init')
  },
  methods: {
    querySearch (queryString, cb) {
      if (queryString) {
        const params = {
          code: '',
          configName: queryString,
          groupId: '',
          projectId: this.filtroProject
        }
        this.axios.post(api.searchFlowConfigListByName, params)
          .then((res) => {
            if (res.data.code === 0) {
              this.searchList = res.data.data.map(item => {
                return item
              })
              cb(this.searchList)
            }
          })
      }
    },
    closePreviewEmit () {
      document.body.style.overflowY = 'auto'
      this.showPreviewDialog = false
    },
    selectProcess (item) {
      this.$router.push({
        path: '/formSubmit',
        query: {
          id: item.id,
          projectId: this.projectId,
          name: item.name
        }
      })
    },
    getList (type) { // 获取流程列表数据
      // type为init时，是列表首次加载(含首次进入页面和切换项目)
      const params = {
        code: '',
        groupId: '',
        groupName: '',
        projectId: this.filtroProject,
        sort: ''
      }
      this.axios.post(api.getFlowConfigListByGroupOfReceiptCenter, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.navList = res.data.data.map((item, index) => {
              const obj = {
                groupId: item.groupId,
                groupName: item.groupName,
                children: [],
                locate: type === 'init' && index === 0 ? true : (index === this.anchor)
              }
              if (item.children && item.children.length > 0) {
                obj.children = item.children.map(item2 => {
                  const obj2 = {
                    id: item2.id,
                    code: item2.code,
                    name: item2.name,
                    projectId: item2.projectId,
                    groupId: item2.groupId,
                    groupName: item2.groupName,
                    desc: item2.desc,
                    icon: item2.icon,
                    iconColour: item2.iconColour,
                    isCommon: item2.isCommon,
                    showOption: false
                  }
                  return obj2
                })
              }
              return obj
            })
            // 自适应高度
            this.$nextTick(function () {
              if (this.navList.length > 0) {
                const dom = document.getElementById('btns-wrap')
                const dom2 = document.getElementById('nav')
                dom.style.height = window.innerHeight - 260 + 'px'
                dom2.style.height = window.innerHeight - 260 + 'px'
                window.addEventListener('resize', () => {
                  dom.style.height = window.innerHeight - 260 + 'px'
                  dom2.style.height = window.innerHeight - 260 + 'px'
                })
              } else {
                const dom = document.getElementById('workOrder-container')
                dom.style.height = window.innerHeight - 260 + 'px'
                window.addEventListener('resize', () => {
                  dom.style.height = window.innerHeight - 260 + 'px'
                })
              }
            })
          }
        })
    },
    mouseOver (index, key) {
      this.index1 = index
      this.index2 = key
      this.navList[index].children[key].showOption = true
      if (this.navList[index].children[key].isCommon === 0) {
        this.btnList[2].name = '删除常用'
        this.btnList[2].icon = 'icon-work-disable'
      } else {
        this.btnList[2].name = '添加常用'
        this.btnList[2].icon = 'icon-work-enable'
      }
    },
    mouseLeave (index, key) {
      this.index1 = index
      this.index2 = key
      this.navList[index].children[key].showOption = false
    },
    setting (i, item) { // i: 按钮类型（预览，提交，添加/删除常用）
      switch (i) {
        case 0: // 预览
          document.body.style.overflowY = 'hidden'
          this.configId = item.id
          this.processName = item.name
          this.showPreviewDialog = true
          break
        case 1: // 提交
          this.$router.push({
            path: '/formSubmit',
            query: {
              id: item.id,
              projectId: this.projectId,
              name: item.name
            }
          })
          break
        case 2: // 添加常用 或 删除常用
          if (item.isCommon === 0) { // 删除
            this.deleteCommonUse(item.id)
          } else { // 添加
            this.addCommonUse(item.id)
          }
          break
      }
    },
    addCommonUse (id) {
      const params = {
        configId: id
        // version: 0 // 版本
      }
      this.axios.post(api.addCommonUse, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.btnList[2].name = '添加常用'
            this.btnList[2].icon = 'icon-work-enable'
            this.$message({
              type: 'success',
              message: '添加成功'
            })
            this.getList()
          }
        })
    },
    deleteCommonUse (id) {
      const params = {
        configId: id
        // version: 0 // 版本
      }
      this.axios.post(api.deleteCommonUse, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.btnList[2].name = '删除常用'
            this.btnList[2].icon = 'icon-work-disable'
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getList()
          }
        })
    },
    locate (index) { // 锚点定位
      this.anchor = index
      // 右侧标题
      this.navList.forEach((item, i) => {
        if (index === i) {
          item.locate = true
        } else {
          item.locate = false
        }
      })
      // 滑动
      let topVal = 0
      for (let i = 0; i < index; i++) {
        const id = 'workOrder' + i
        const height = document.getElementById(id).offsetHeight
        topVal += height
      }
      this.$nextTick(() => {
        document.getElementById('btns-wrap').scrollTo({
          behavior: 'smooth', // 平滑过渡
          top: topVal
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-work-center-wrap
  display flex
  margin-left 204px
  width calc(100% - 226px)
  .container
    width 100%
    background-color #272930
    .search-wrap
      padding 0 32px
      display flex
      justify-content space-between
      align-items center
      height 88px
      background #272930
      box-sizing border-box
      .search
        display flex
        .w-input-sty
          width 320px
          height 40px
          background #373A43
          border-radius 2px
          & >>> .el-input__inner
            width 320px
            height 40px !important
    .workOrder-container
      border-radius 2px
      .workOrder-btns-container
        display flex
        justify-content space-between
        .btns-wrap
          padding 0 40px
          flex 1
          background #272930
          overflow-y auto
          .btns-row
            min-height 155px
            border-bottom 1px solid #373A43
            &:last-child
              border none
            .title
              height 75px
              font-size 14px
              font-weight 400
              line-height 75px
              color #B2B3BD
            .btns
              display flex
              flex-wrap wrap
              .btn
                position relative
                display flex
                margin-right 10px
                margin-bottom 33px
                height 64px
                align-items center
                width 270px
                .icon-img
                  margin-right 9px
                  width 48px
                  height 48px
                  line-height 48px
                  text-align center
                  font-size 24px
                  color #fff
                  border-radius 8px
                .name
                  width 160px
                  font-size 18px
                  font-weight 400
                  color #FFFFFF
                .desc
                  width 200px
                  font-size 14px
                  font-weight 400
                  color #808191
                .option
                  position absolute
                  display flex
                  align-items center
                  justify-content space-around
                  margin-left 8px
                  color #fff
                  width 264px
                  height 64px
                  background rgba(0, 0, 0, 0.8)
                  border-radius 16px
                  z-index 10
                  .item
                    cursor pointer
                    text-align center
                    font-size 12px
                    font-weight 400
                    color #fff
                    i
                      margin-bottom 4px
                      color #fff
                      font-size 18px
            .no-data
              padding 0
              text-align center
              font-size 14px
              color #b2b3bd
          &::-webkit-scrollbar
            width 4px
          &::-webkit-scrollbar-track
            border-radius 10px
          /* 滚动条的滑轨背景颜色 */
          &::-webkit-scrollbar-thumb
            background-color #373A43
            border-radius 10px
        .nav
          width 180px
          background #272930
          font-size 14px
          font-weight 400
          line-height 48px
          color #808191
          box-sizing border-box
          ul
            margin 40px auto auto 28px
            overflow-y auto
            li
              text-indent 28px
              span
                display flex
                align-items center
                cursor pointer
            .active
              text-indent 0
              color #FFF
              font-size 18px
              font-weight 500
              i
                color #fff
                font-size 8px
                transform scale(0.5)
                margin-right 8px
            &::-webkit-scrollbar
              width 4px
            &::-webkit-scrollbar-track
              border-radius 10px
            /* 滚动条的滑轨背景颜色 */
            &::-webkit-scrollbar-thumb
              background-color #373A43
              border-radius 10px
      .no-data
        width 100%
        display flex
        justify-content center
        align-items center
.my-autocomplete
  span
    margin-right 10px
  .search-item-icon
    display inline-block
    width 20px
    height 20px
    line-height 20px
    border-radius 5px
    text-align center
    background #f00
    color #fff
  .desc
    font-size 12px
</style>
